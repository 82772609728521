/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  Divider,
  Space,
  Typography,
  Spin,
  Popconfirm,
  message,
} from "antd";
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import { UsergroupAddOutlined, DownloadOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import { flatten } from "lodash";
import moment from "moment";

const { Title, Text } = Typography;

const FirstPartyDataDetailView = props => {
  const {
    activateHashedEmailList,
    refetchHashedEmailList,
    deleteHashedEmailList,
    processDeviceIds,
  } = props;
  const { id } = useParams();

  const [hashedEmailList, setHashedEmailList] = useState({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const renderLabelValuePair = (label, val) => {
    return (
      <>
        <Space direction="vertical">
          <Text strong style={{ color: "rgba(0, 0, 0, 0.45)" }}>
            {label}
          </Text>
          <Text>{val}</Text>
        </Space>
      </>
    );
  };

  const handleLoadData = async () => {
    try {
      setLoading(true);
      const response = await refetchHashedEmailList({
        id: id,
      });

      if (response && response.data) {
        const hashedEmailList = response.data.hashedEmailLists[0];

        const dspSegmentIds = hashedEmailList.dspSegmentIds[0];

        const emailCount =
          hashedEmailList && hashedEmailList.hashedEmails
            ? hashedEmailList.hashedEmails.length.toFixed(2).toLocaleString()
            : 0;

        const status = hashedEmailList.activated ? "Activated" : "Inactive";

        const matchRate =
          typeof hashedEmailList.matchRate === "number" &&
          Number.isFinite(hashedEmailList.matchRate)
            ? hashedEmailList.matchRate.toFixed(2).toLocaleString() + "%"
            : "-";

        const deviceCount =
          typeof hashedEmailList.deviceCount === "number"
            ? hashedEmailList.deviceCount.toFixed(2).toLocaleString()
            : "-";

        const orgName = hashedEmailList.orgs[0].name;

        setHashedEmailList({
          ...hashedEmailList,
          ...dspSegmentIds,
          emailCount,
          deviceCount,
          status,
          matchRate,
          orgName,
        });
      }
    } catch (error) {
      message.error("Error loading data " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleActivateEmails = async () => {
    try {
      setLoading(true);
      const response = await activateHashedEmailList({});
      if (
        response &&
        response.data &&
        response.data.activateHashedEmailList &&
        response.data.activateHashedEmailList.success
      ) {
        message.success("Emails Activated");
        await handleLoadData();
      } else if (
        response &&
        response.data &&
        response.data.activateHashedEmailList &&
        response.data.activateHashedEmailList.error
      ) {
        message.error(response.data.activateHashedEmailList.error);
      } else {
        message.error(
          "No emails were activated. Please make sure there are emails to activate."
        );
      }
    } catch (error) {
      message.error("Error activating emails " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleProcessDeviceIds = async () => {
    try {
      setLoading(true);

      if (!hashedEmailList.activated) {
        return message.error(
          "Cannot process device ID's for an inactive audience"
        );
      }

      const response = await processDeviceIds({
        variables: { emailListId: hashedEmailList.id },
      });

      await handleLoadData();

      return response && response.data.processDeviceIds.deviceIdsProcessed > 0
        ? message.success("Email list processed.")
        : message.warning("No device IDs were processed.");
    } catch (error) {
      message.error("Error processing device ID's " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAudience = async () => {
    try {
      setLoading(true);
      if (hashedEmailList.activated) {
        return message.error("Cannot delete an activated audience");
      }
      await deleteHashedEmailList(hashedEmailList);
      message.success("Audience deleted, redirecting to main page...");
      setTimeout(() => {
        history.push("/audience/main/1");
      }, 1000);
    } catch (err) {
      message.error("Error deleting audience " + err.message);
    } finally {
      setLoading(false);
    }
  };

  const ProcessDeviceIdsButton = () => (
    <Popconfirm
      title="Do you want to process this email list?"
      onConfirm={handleProcessDeviceIds}
      okText="Yes"
      cancelText="No"
    >
      <Button style={{ marginRight: "8px" }}>Process Device IDs</Button>
    </Popconfirm>
  );

  const DeleteAudienceButton = () => (
    <Popconfirm
      title="Are you sure you want to delete this audience?"
      onConfirm={handleDeleteAudience}
      okText="Yes"
      cancelText="No"
    >
      <Button>Delete Audience</Button>
    </Popconfirm>
  );
  //define custom header then flatten data then map to only get id
  const headers = [
    { label: "Hashed Email", key: "id" }, // Customize header name to 'Hashed Email' and key to 'id'
  ];
  const idColumn = flatten(hashedEmailList.hashedEmails).map(email => ({
    id: email.id,
  }));

  useEffect(() => {
    handleLoadData();
  }, []);

  return (
    <React.Fragment>
      <PageHeaderLayout
        titleIcon={<UsergroupAddOutlined />}
        title={hashedEmailList.name || "Audience"}
        rightContent={[
          <Button
            key="download-csv"
            disabled={!hashedEmailList || hashedEmailList.length === 0}
            style={{ marginRight: "5px" }}
          >
            <CSVLink
              filename={`FirstPartyData(${hashedEmailList.name}).csv`}
              style={{ textDecoration: "none" }}
              headers={headers}
              data={idColumn}
            >
              <DownloadOutlined /> Download CSV
            </CSVLink>
          </Button>,
          <Button key="back" onClick={() => history.push("/audience/main/1")}>
            Back
          </Button>,
        ]}
      />

      <PageContentLayout>
        <Spin spinning={loading}>
          <Card
            title={`Audience Details`}
            bordered={false}
            style={{ minHeight: "350px" }}
            extra={
              <div>
                <Button
                  style={{ marginRight: "8px" }}
                  onClick={handleActivateEmails}
                >
                  Activate Emails
                </Button>
                <ProcessDeviceIdsButton />
                <DeleteAudienceButton />
              </div>
            }
          >
            {hashedEmailList && (
              <Row style={{ height: "100%" }}>
                <Col span={11}>
                  <Row>
                    <Col span={6}>
                      {renderLabelValuePair(
                        "Email Count",
                        hashedEmailList.emailCount
                      )}
                    </Col>
                    <Col span={6}>
                      {renderLabelValuePair(
                        "Devices",
                        hashedEmailList.deviceCount
                      )}
                    </Col>
                    <Col span={6}>
                      {renderLabelValuePair(
                        "Match Rate",
                        hashedEmailList.matchRate
                      )}
                    </Col>
                    <Col span={6}>
                      {renderLabelValuePair("Status", hashedEmailList.status)}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "2em" }}>
                    <Col span={12}>
                      {renderLabelValuePair(
                        "Last Processed",
                        hashedEmailList && hashedEmailList.processedAt
                          ? moment(hashedEmailList.processedAt).format(
                              "MM/DD/YYYY - hh:mma"
                            )
                          : "-"
                      )}
                    </Col>
                    <Col span={12}>
                      {renderLabelValuePair(
                        "Uploaded",
                        hashedEmailList && hashedEmailList.uploadedAt
                          ? moment(hashedEmailList.uploadedAt).format(
                              "MM/DD/YYYY - hh:mma"
                            )
                          : "-"
                      )}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "2em" }}>
                    <Col span={12}>
                      {renderLabelValuePair(
                        "Advertiser",
                        hashedEmailList.orgName
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col span={1}>
                  <Divider type="vertical" style={{ height: "100%" }} />
                </Col>
                <Col span={11}>
                  <Title level={5}>ASSOCIATED INFO</Title>
                  <Row style={{ marginTop: "1.7em" }}>
                    <Col span={12}>
                      {renderLabelValuePair("ID", hashedEmailList.segmentId)}
                    </Col>
                    <Col span={12}>
                      {renderLabelValuePair(
                        "Audience Name",
                        hashedEmailList.segmentName
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Card>
        </Spin>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default FirstPartyDataDetailView;
