import React from "react";
import { Card, Space, Typography, Input, Form } from "antd";

const { Text } = Typography;
const { TextArea } = Input;

const CampaignRequestorNotes = () => {
  return (
    <>
      <Card title="CAMPAIGN NOTES">
        <Space direction="vertical" size="small" style={{ width: "100%" }}>
          <Text>
            Are there any additional details that the Ops Team should know?
          </Text>
          <Form.Item name="targetingCriteria">
            <TextArea rows={2} />
          </Form.Item>
        </Space>
      </Card>
    </>
  );
};

export default CampaignRequestorNotes;
