import gql from "graphql-tag";

const GET_CAMPAIGN_ORDER_REQUESTOR_ADVERTISERS = gql`
  query CampaignRequestorAdvertisers($where: OrgWhereInput) {
    orgs(where: $where) {
      id
      name
      cpm
    }
  }
`;

export default GET_CAMPAIGN_ORDER_REQUESTOR_ADVERTISERS;
