import gql from "graphql-tag";

export const CampaignFields = gql`
  fragment CampaignFields on Campaign {
    id
    name
    startDate
    endDate
    budgetTotal
    budgetByDay
    cpm
    targetingType
    targetingRegions {
      id
      name
      isActive
      isPresetList
      regions
      createdAt
    }
    targetingLocations {
      id
      name
      type
      isAmplifyDefault
      geoTargets {
        ...CampaignGeoTargetFields
      }
      eventsTargets {
        id
      }
      createdAt
      createdBy {
        id
        name
      }
      orgs {
        id
        name
      }
    }
    targetingCountries {
      id
      code
      name
    }
    audienceType
    firstPartyDataAudiences {
      id
      name
      activated
      deviceCount
      matchRate
      isProcessedByStatera
      createdAt
      dspSegmentIds
      hashedEmails {
        id
      }
      # hashedEmailsCount
    }
    orgs {
      id
    }
    adGroup {
      id
      clickthrough
      adGroupCreatives {
        id
        name
        startDate
        endDate
        createdAt
        updatedAt
        medias {
          id
          name
          size
          fileType
          height
          width
          length
          key
          url
          type
          mediaNotes
          status
        }
        isAmplifyDefault
      }
    }
  }
`;

export const CampaignGeoTargetFields = gql`
  fragment CampaignGeoTargetFields on GeoTarget {
    vwiUpdated
    id
    name
    start
    end
    status
    orgs {
      id
      name
    }
    circles {
      id
      radius
      location {
        id
        name
        formattedAddress
        zip
        street
        city
        county
        state
        country
        lat
        lng
      }
      coordinate {
        id
        lat
        lng
      }
    }
    polygons {
      id
      geometry
      location {
        id
        name
        formattedAddress
        zip
        street
        city
        county
        state
        country
        lat
        lng
      }
    }
    location {
      id
      name
      formattedAddress
      zip
      street
      city
      county
      state
      country
      lat
      lng
    }
  }
`;

export const CampaignRequestorFields = gql`
  ${CampaignGeoTargetFields}
  ${CampaignFields}

  fragment CampaignRequestorFields on CampaignOrder {
    # Details
    id
    name
    description
    type
    cpm
    advertiser {
      id
      name
    }
    status

    # Duration & Budget
    startDate
    endDate
    budget

    # Categories
    isRegulated
    advertiserType
    measureWalkInTraffic
    category
    attributionTargets {
      ...CampaignGeoTargetFields
    }

    # Attribution Reporting
    awarenessCampaign
    transactionTarget
    transactionsConfig {
      id
      appId
      cart
      traffId
      tracksBasketItems
      gtmAccess
      gtmStatus
      tag
    }
    signUpCampaign
    signUpConfig {
      id
      appId
      locationId
      name
    }
    pageViewConfig {
      id
      appId
      locationId
    }
    geoTargets {
      ...CampaignGeoTargetFields
    }
    targetingCriteria

    # Line Items
    campaigns {
      ...CampaignFields
    }

    reviewerId
    notes
    histories {
      id
      action
      author {
        id
        name
      }
      property
      campaignOrder {
        id
        name
      }
      data
      createdAt
      updatedAt
    }
    dspConfig
    version
    requesterVersion
  }
`;
