import React, { useState } from "react";
import { Row, Col, Progress, Tooltip, Tag, Switch, Space } from "antd";
import Text from "antd/lib/typography/Text";
import { ShoppingCartOutlined, UserAddOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import {
  getCTRFromAggregate,
  formatWholeNumber,
  dailyTotalSpentFromAggregate,
  formatCurrency,
  getPacingGoal,
  getDailyPacing,
  campaignOrderPreCalculate,
  totalSpentFromAggregate,
  formatNumber2SigFig,
} from "../../../../core/utils/campaigns.js";
// Global Styles
import {
  TableColumnTitle,
  PrimaryTableRowText,
  SecondaryTableRowText,
  FlightDateText,
  StandardTableRowText,
} from "../../../shared/globalStyling/styledText";
import PaginationTable from "../../../shared/PaginationTable";
import moment from "moment";
import MiniChart from "../../../shared/MiniChart";
import { isEmpty, isNil } from "lodash";
import { aggregateDisplayReports } from "../../../../core/utils/dataManipulating/aggregateDisplayReports";
import { TableColumnsDropDown } from "./TableColumnsDropDown";
import StatusTag from "../shared/StatusTag";
import { ReactComponent as ReactLogo } from "../../../../assets/icons/campaigns/retaillocation.svg";
import { ReactComponent as WalkinsEnabled } from "../../../../assets/icons/campaigns/walkins_enabled.svg";
import { ReactComponent as SignUpEnabled } from "../../../../assets/icons/campaigns/signup_2.svg";
import { ReactComponent as ShoppingCartBasketItem } from "../../../../assets/icons/campaigns/shopping-cart-basket-item.svg";
import { ReactComponent as ShoppingCartBasketItemLocation } from "../../../../assets/icons/campaigns/shopping-cart-basket-item-location.svg";
import { primaryTextStyle } from "../../../shared/globalStyling/style.js";

const Frag = React.Fragment;

const CampaignsTable = ({
  data,
  history,
  possibleFields,
  selectedTableColumns,
  onTableColChange,
  location,
  total,
  current,
  selectedRowKeys,
  onRowChange,
  isDemo,
  currencyFormat,
  selectedRows,
}) => {
  const params = new URLSearchParams(window.location.search);
  const getSortOrder = key => {
    const sortParams = params.get("sort");
    const splitSort = sortParams && sortParams.split("_");
    return sortParams && splitSort[0] === key
      ? splitSort[1] === "DESC"
        ? "descend"
        : "ascend"
      : false;
  };
  const pageSize = params.get("pageSize")
    ? parseInt(params.get("pageSize"))
    : 10;
  //trigger this when you want to change column render without triggering the sort
  let isColumnChange = false;

  const [switchStateSales, setSwitchStateSales] = useState(false);
  const [switchState, setSwitchState] = useState(false);
  const [footerBudgetState, setFooterBudgetState] = useState(false);

  const getColumn = {
    Name: field => {
      return {
        title: <TableColumnTitle text={"Name"} />,
        dataIndex: "name",
        key: "name",
        width: "15%",
        align: "left",
        render: (text, record) => {
          const getIsRequestChange =
            record.isRequestChange && record.isRequestChange;

          const orgs = record.orgs;
          let sharedOrgs;
          if (orgs) {
            let count = 0;
            const maxOrgTags = 2;
            let moreOrgs = [];

            sharedOrgs = orgs.map(org => {
              if (count < maxOrgTags) {
                count++;
                return (
                  <Tag key={org.name} color="gray">
                    {org.name}
                  </Tag>
                );
              } else if (count >= maxOrgTags) {
                count++;
                moreOrgs.push(<div key={org.name}>{org.name}</div>);
              }
              return null;
            });

            if (count > maxOrgTags) {
              sharedOrgs.push(
                <Tooltip
                  key="moreOrgs"
                  placement="right"
                  title={<>{moreOrgs}</>}
                >
                  <Tag color="gray">+{count - maxOrgTags} more</Tag>
                </Tooltip>
              );
            }
          }

          return (
            <Frag>
              <Row type="flex" justify="start">
                <Col style={{ zIndex: 1, width: "100%" }}>
                  {sharedOrgs && sharedOrgs.length > 0 && !isDemo && (
                    <Row
                      style={{
                        overflowX: "visible",
                        display: "inline-block",
                        whiteSpace: "nowrap",
                        marginBottom: "5px",
                      }}
                    >
                      {sharedOrgs}
                    </Row>
                  )}
                  <PrimaryTableRowText
                    text={
                      <div
                        style={{
                          wordBreak: "break-word",
                          whiteSpace: "normal",
                        }}
                      >
                        {text}
                        {getIsRequestChange ===
                          "Request Change on Campaign" && (
                          <Tooltip title={getIsRequestChange}>
                            <Tag color="orange" style={{ marginLeft: "5px" }}>
                              update!
                            </Tag>
                          </Tooltip>
                        )}
                      </div>
                    }
                  />
                </Col>
              </Row>
            </Frag>
          );
        },
        sorter: true,
        sortOrder: getSortOrder("name"),
      };
    },
    Status: field => {
      return {
        title: <TableColumnTitle text={"Status"} />,
        dataIndex: "status",
        key: "status",
        width: "6%",
        align: "left",
        footerContent: "",
        render: (text, record) => {
          return (
            <Row type="flex" justify="start">
              <Col>
                <StatusTag
                  startDate={
                    record &&
                    moment(record.startDate)
                      .seconds(0)
                      .milliseconds(0)
                      .toISOString()
                      ? moment(record.startDate)
                          .seconds(0)
                          .milliseconds(0)
                          .toISOString()
                      : "N/A"
                  }
                  endDate={
                    record &&
                    moment(record.endDate)
                      .seconds(0)
                      .milliseconds(0)
                      .toISOString()
                      ? moment(record.endDate)
                          .seconds(0)
                          .milliseconds(0)
                          .toISOString()
                      : "N/A"
                  }
                  attributionWindow={
                    record && record.attributionWindow
                      ? record.attributionWindow
                      : "N/A"
                  }
                  statusCampaign={record.status && record.status}
                />
              </Col>
            </Row>
          );
        },
      };
    },
    "Days Remaining": field => {
      return {
        title: <TableColumnTitle text={"Days Remaining"} />,
        dataIndex: "daysRemaining",
        align: "left",
        width: "8%",
        footerContent: "",
        render: (text, record) => {
          const endDate = new Date(record.endDate);
          const today = new Date();
          if (endDate < today) {
            return <FlightDateText text={"0"} />;
          }
          const diffTime = Math.abs(endDate - today);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          return <FlightDateText text={diffDays.toString()} />;
        },
      };
    },
    "Start Date": field => {
      return {
        title: <TableColumnTitle text={"Start Date"} />,
        dataIndex: "startDate",
        key: "startDate",
        align: "left",
        width: "8%",
        footerContent: " ",
        render: (text, record) => {
          if (!text) {
            return <div />;
          }
          const startDate = record.startDate ? moment(record.startDate) : null;
          return (
            <FlightDateText
              text={startDate ? startDate.format("MM/DD/YY") : null}
            />
          );
        },
        // Custom Sorter
        sorter: (a, b) => {
          const endDateA = moment(a.endDate);
          const endDateB = moment(b.endDate);

          // DESC
          if (endDateA.isBefore(endDateB)) {
            return -1;
          }
          // ASC
          if (endDateA.isAfter(endDateB)) {
            return 1;
          }
          return 0;
        },
        sortOrder: getSortOrder("startDate"),
      };
    },
    "End Date": field => {
      return {
        title: <TableColumnTitle text={"End Date"} />,
        dataIndex: "endDate",
        key: "endDate",
        align: "left",
        width: "8%",
        footerContent: " ",
        render: (text, record) => {
          if (!text) {
            return <div />;
          }
          const endDate = record.endDate ? moment(record.endDate) : null;
          return (
            <FlightDateText
              text={endDate ? endDate.format("MM/DD/YY") : null}
            />
          );
        },
        // Custom Sorter
        sorter: (a, b) => {
          const endDateA = moment(a.endDate);
          const endDateB = moment(b.endDate);

          // DESC
          if (endDateA.isBefore(endDateB)) {
            return -1;
          }
          // ASC
          if (endDateA.isAfter(endDateB)) {
            return 1;
          }
          return 0;
        },
        sortOrder: getSortOrder("endDate"),
      };
    },
    Performance: field => {
      return {
        title: <TableColumnTitle text={"Performance"} />,
        dataIndex: "performance",
        key: "performanceStatus",
        align: "left",
        width: "8%",
        footerStyle: "block",
        ellipsis: true,
        footerSums: [
          {
            property: "aggregateData.overallData.aggImpressions",
            formatter: "",
            title: "Impressions",
          },
          {
            property: "aggregateData.overallData.aggClicks",
            formatter: "",
            title: "Clicks",
          },
        ],
        render: (text, { aggregateData, data }) => {
          return (
            <Row type="flex">
              <Col style={{ minWidth: "50px" }}>
                {!isEmpty(aggregateData) ? (
                  <>
                    <Row type="flex">
                      <PrimaryTableRowText text={`Impressions:\u00A0‏‏‎‎`} />
                      <StandardTableRowText
                        text={` ${Number(
                          parseInt(
                            aggregateData.overallData.aggImpressions
                          ).toFixed(0)
                        ).toLocaleString()}`}
                      />
                    </Row>
                    <Row type="flex">
                      <PrimaryTableRowText text={`Clicks:\u00A0‏‏‎‎`} />
                      <StandardTableRowText
                        text={`${Number(
                          parseInt(aggregateData.overallData.aggClicks).toFixed(
                            0
                          )
                        ).toLocaleString()}`}
                      />
                    </Row>
                    <Row type="flex">
                      <PrimaryTableRowText text={`CTR:\u00A0‏‏‎‎`} />
                      <StandardTableRowText
                        text={`${getCTRFromAggregate(
                          aggregateData.overallData
                        )}%`}
                      />
                    </Row>
                  </>
                ) : (
                  <PrimaryTableRowText text={`-`} />
                )}
              </Col>
            </Row>
          );
        },
      };
    },
    Budget: field => {
      function handleValueChange(e) {
        //False = Details; True = Progress
        switchState ? setSwitchState(false) : setSwitchState(true);
        footerBudgetState
          ? setFooterBudgetState(false)
          : setFooterBudgetState(true);
      }
      let footerSums = [
        {
          property: "budget",
          isCurrency: true,
          title: "Budget",
        },
        {
          property: "impressionsGoal",
          formatter: "",
          title: "Impressions Goal",
        },
      ];
      let footerStyle = "block";
      if (footerBudgetState) {
        footerSums = [
          {
            property: "budgetSpent",
            isCurrency: true,
            title: "",
          },
          {
            property: "budget",
            formatter: "spent of ",
            isCurrency: true,
            title: "",
          },
        ];
        footerStyle = "inline";
      }
      const tooltipDescription = `𝗗𝗲𝘁𝗮𝗶𝗹𝘀: Displays Budget, Impressions Goal, & Cost per mille (CPM)
      𝗣𝗿𝗼𝗴𝗿𝗲𝘀𝘀: Display progress bar indicator of your remaining budget (I.E 100% means budget is totally spent)
      `;

      return {
        title: (
          <React.Fragment>
            <TableColumnTitle text={"Budget"} />
            <Tooltip title={tooltipDescription} style={{ fontSize: "10px" }}>
              <Switch
                onClick={() => {}}
                onChange={() => {
                  isColumnChange = true;
                  handleValueChange();
                }}
              />
            </Tooltip>
          </React.Fragment>
        ),
        dataIndex: "budget",
        key: `budget`,
        align: "left",
        width: "10%",
        ellipsis: true,
        footerSums: footerSums,
        footerStyle: footerStyle,
        render: (budget, row) => {
          const { cpm, aggregateData } = row;
          let impressionGoal = 0;
          let impressions = 0;

          try {
            impressions = aggregateData.overallData.aggImpressions;
          } catch (e) {
            impressions = 0;
          }

          if (cpm && budget) {
            impressionGoal = (budget / cpm) * 1000;
          }

          const budgetSpent = cpm * (impressions / 1000);
          const budgetRemaining = budget - budgetSpent;
          const budgetRemainingPercentage = (budgetRemaining / budget) * 100;
          const percentageProgress = 100 - budgetRemainingPercentage;

          if (!switchState) {
            return (
              <Row type="flex" justify="start">
                <Col>
                  <Row type="flex">
                    <PrimaryTableRowText text={`Budget:\u00A0‏‏‎‎`} />
                    <StandardTableRowText
                      text={`${formatCurrency(budget, currencyFormat)}`}
                    />
                  </Row>

                  <Row type="flex">
                    <PrimaryTableRowText text={`Impressions Goal:\u00A0‎`} />
                    <StandardTableRowText
                      text={
                        ` ${Number(
                          parseInt(impressionGoal).toFixed(0)
                        ).toLocaleString()}` || "0"
                      }
                    />
                  </Row>

                  <Row type="flex">
                    <PrimaryTableRowText text={`CPM:\u00A0‎`} />
                    <span>
                      <StandardTableRowText
                        text={
                          cpm !== null
                            ? `${currencyFormat.symbol}${
                                // check if cpm has cents (decimals) using modulo
                                // show decimals only if cpm has cents value
                                cpm % 1 !== 0 ? cpm.toFixed(2) : Math.floor(cpm)
                              }`
                            : `${currencyFormat.display}0`
                        }
                      />
                    </span>
                  </Row>
                </Col>
              </Row>
            );
          }
          return (
            <React.Fragment>
              <PrimaryTableRowText
                text={`${formatCurrency(
                  Number(
                    parseInt(
                      budgetSpent > budget ? budget : budgetSpent
                    ).toFixed(0)
                  ),
                  currencyFormat
                )} spent of ${formatCurrency(
                  Number(parseInt(budget <= 1 ? 0 : budget).toFixed(0)),
                  currencyFormat
                )}`}
              />
              <Progress
                style={{
                  fontWeight: "bold",
                }}
                size="small"
                strokeColor={{
                  from: "#108ee9",
                  to: "#87d068",
                }}
                percent={parseInt(
                  percentageProgress < 0 ? 100 : percentageProgress
                )}
                status={percentageProgress >= 100 ? "success" : "active"}
              />
              {impressions > impressionGoal && (
                <>
                  <Tag color="green" style={{ marginTop: "8px" }}>
                    Value added impressions: <UserAddOutlined />
                    {" +"}
                    {Number(
                      parseInt(impressions - impressionGoal).toFixed(0)
                    ).toLocaleString()}
                  </Tag>
                </>
              )}
            </React.Fragment>
          );
        },
        sorter: true,
        sortOrder: getSortOrder("budget"),
      };
    },
    //Preserved for referencing - TODO - Implement profit / loss calculation - Currently commented out in constants.js
    Spent: field => {
      return {
        title: <TableColumnTitle text={"Spent"} />,
        dataIndex: "spent",
        key: `aggregateData.overallData.aggTotalCost`,
        align: "left",
        width: "14%",
        ellipsis: true,
        footerSum: true,
        footerSumFixed: 0,
        footerSumFormatter: "$",
        render: (text, { aggregateData, startDate, endDate, budget }) => {
          const dailyTotalSpent = dailyTotalSpentFromAggregate(aggregateData);
          const overallTotalSpent = totalSpentFromAggregate(aggregateData);
          const pacingGoals = getPacingGoal(startDate, endDate, budget);
          const dailyPacing = getDailyPacing(pacingGoals, dailyTotalSpent);
          return (
            <Row type="flex" justify="start">
              <Col>
                <PrimaryTableRowText
                  text={`${formatCurrency(overallTotalSpent, currencyFormat)}`}
                />
                <Row type="flex" justify="space-between" align="middle">
                  <SecondaryTableRowText text={`24hr Pace:`} />
                  <span style={{ marginLeft: "5px" }}>
                    <SecondaryTableRowText
                      text={dailyPacing ? `${dailyPacing}%` : "N/A"}
                      color={
                        dailyPacing
                          ? dailyPacing > 50
                            ? "#00DE51"
                            : "#EC5D58"
                          : "#BCBDC0"
                      }
                    />
                  </span>
                </Row>
              </Col>
            </Row>
          );
        },
      };
    },
    Lift: field => {
      return {
        title: <TableColumnTitle text={"Lift"} />,
        dataIndex: field.key,
        key: `aggregateData.overallData.${field.dataAccessor}`,
        align: "left",
        width: "8%",
        footerSum: true,
        render: (text, { aggregateData, data }) => {
          return (
            <Row type="flex" justify="start" align="middle">
              <Col style={{ minWidth: "75px" }}>
                {!isEmpty(aggregateData) ? (
                  <PrimaryTableRowText
                    text={`${Number(
                      aggregateData.overallData[field.dataAccessor]
                    ).toLocaleString()}%`}
                  />
                ) : (
                  <PrimaryTableRowText text={`-`} />
                )}
              </Col>
            </Row>
          );
        },
      };
    },
    Pacing: field => {
      return {
        title: <TableColumnTitle text={"Pacing"} />,
        dataIndex: null, //uses chart data
        key: `aggregateData.overallData.${field.key}`,
        align: "left",
        width: "8%",
        footerSum: true,
        render: (text, row) => {
          const { aggregateData, pacingData } = row;
          if (aggregateData && !isEmpty(aggregateData)) {
            const { byDate } = aggregateData;
            const chartData = byDate;
            if (byDate && !isEmpty(byDate)) {
              const { cpm, budget, startDate, endDate, aggregateData } = row;

              if (!budget || !cpm || !budget) {
                return (
                  <Row type="flex" justify="start">
                    <Col>
                      <PrimaryTableRowText text={`N/A`} />
                    </Col>
                  </Row>
                );
              }

              let impressionsSpent = 0;
              if (aggregateData && aggregateData.overallData) {
                impressionsSpent = aggregateData.overallData.aggImpressions;
              }

              let daysPast = 0;
              let dailyImpressionsGoal = 0;
              let impressionGoal = 0;
              if (cpm && budget && budget !== 1) {
                impressionGoal = (budget / cpm) * 1000;
              }

              const currentDate = moment()
                .utc() //Convert to UTC
                .startOf("D") //Start of UTC date
                .format(`YYYY-MM-DD`);

              const impressionsleft = impressionGoal - impressionsSpent;

              let todaysImpressions = 0;
              let impressionsUpToToday = 0;
              if (chartData && !isEmpty(chartData)) {
                //Today is in progress - So no need to count today
                chartData.forEach(chart => {
                  const { xAxis, impressions } = chart;
                  const chartDate = moment(xAxis)
                    .utc()
                    .format(`YYYY-MM-DD`);

                  if (chartDate !== currentDate) {
                    impressionsUpToToday = impressionsUpToToday + impressions; //total of impressions up to today
                    daysPast = daysPast + 1;
                  } else {
                    todaysImpressions = impressions;
                  }
                });
              }

              const b = moment(startDate).utc();
              const a = moment(endDate).utc();
              let dayAdjust = 1;
              if (a <= currentDate) {
                //campaign is over
                dayAdjust = 0;
              }

              const daysOfFlight = a.diff(b, "days");
              const daysLeft = daysOfFlight - daysPast + dayAdjust; //include today with date adjust

              if (daysLeft && impressionsleft) {
                dailyImpressionsGoal = impressionsleft / daysLeft;
              } else dailyImpressionsGoal = 0;

              let textColor = "#ff4d4f"; // danger
              if (pacingData && pacingData.pace) {
                switch (true) {
                  case pacingData.pace >= 95:
                    textColor = "#52c41a"; // success
                    break;
                  case pacingData.pace >= 91 && pacingData.pace <= 95:
                    textColor = "#faad14"; // warning
                    break;
                  default:
                    break;
                }
              }

              return (
                <Row type="flex" justify="start">
                  <Col>
                    <Text style={primaryTextStyle}>
                      Pace:{" "}
                      <span style={{ color: textColor }}>
                        {pacingData && pacingData.pace !== null
                          ? formatNumber2SigFig(pacingData.pace)
                          : 0}
                        %
                      </span>
                    </Text>
                    <PrimaryTableRowText
                      text={`${
                        impressionsleft < 0 ? "Overage: " : "Remaining: "
                      }${formatWholeNumber(impressionsleft)}`}
                    />
                    <Row type="flex" justify="space-between" align="middle">
                      <SecondaryTableRowText
                        text={
                          dailyImpressionsGoal
                            ? `Daily: ${formatWholeNumber(
                                dailyImpressionsGoal
                              )}`
                            : "0"
                        }
                        color={"#BCBDC0"}
                      />
                    </Row>
                  </Col>
                  <Row type="flex" justify="space-between" align="middle">
                    <SecondaryTableRowText text={`Today:`} />
                    <span style={{ marginLeft: "5px" }}>
                      <SecondaryTableRowText
                        text={
                          todaysImpressions
                            ? `${formatWholeNumber(todaysImpressions)}`
                            : "0"
                        }
                        color={"#BCBDC0"}
                      />
                    </span>
                  </Row>
                </Row>
              );
            }
          } else {
            return (
              <Row type="flex" justify="start">
                <Col>
                  <PrimaryTableRowText text={`N/A`} />
                </Col>
              </Row>
            );
          }
        },
      };
    },
    Sales: _field => {
      const tooltipDescription =
        "Details: Shows a mini-chart of transaction sales when the switch is toggled to the ON position.";

      let footerSums = [];

      if (!switchStateSales) {
        footerSums = [
          {
            property:
              "aggregateData.overallData.aggEvents.transactionsTotalCount",
            formatter: "",
            title: "Orders",
          },
          {
            property:
              "aggregateData.overallData.aggEvents.transactionTotalRevenue",
            isCurrency: true,
            title: "Rev",
          },
        ];
      }

      return {
        title: (
          <React.Fragment>
            <TableColumnTitle text={"Sales"} />
            <Tooltip title={tooltipDescription} style={{ fontSize: "10px" }}>
              <Switch
                onClick={() => {}}
                onChange={() => {
                  isColumnChange = true;
                  switchStateSales
                    ? setSwitchStateSales(false)
                    : setSwitchStateSales(true);
                }}
              />
            </Tooltip>
          </React.Fragment>
        ),
        dataIndex: "Sales",
        key: `Sales`,
        align: "left",
        width: "8%",
        footerStyle: "block",
        footerSums: footerSums,
        sorter: (a, b) => {
          let impressionsA,
            impressionsB = 0;
          let overallDataA, overallDataB;

          try {
            overallDataA = a.aggregateData.overallData;
            overallDataB = b.aggregateData.overallData;
            impressionsA = overallDataA.aggImpressions;
            impressionsB = overallDataB.aggImpressions;
          } catch (e) {
            impressionsA = 0;
            impressionsB = 0;
          }

          const budgetSpentA = a.cpm * (impressionsA / 1000);
          const budgetSpentB = b.cpm * (impressionsB / 1000);

          const roasA =
            overallDataA &&
            overallDataA.aggEvents &&
            overallDataA.aggEvents.transactionTotalRevenue
              ? parseFloat(
                  overallDataA.aggEvents.transactionTotalRevenue / budgetSpentA
                )
              : 0;
          const roasB =
            overallDataB &&
            overallDataB.aggEvents &&
            overallDataB.aggEvents.transactionTotalRevenue
              ? parseFloat(
                  overallDataB.aggEvents.transactionTotalRevenue / budgetSpentB
                )
              : 0;

          return roasA - roasB;
        },
        sortOrder: getSortOrder("Sales"),
        render: (text, { aggregateData, attributionTargets, cpm }) => {
          let impressions = 0;
          try {
            impressions = aggregateData.overallData.aggImpressions;
          } catch (e) {
            impressions = 0;
          }
          const budgetSpent = cpm * (impressions / 1000);
          if (!switchStateSales) {
            return (
              <Row type="flex" justify="start" align="middle">
                <Col style={{ minWidth: "50px" }}>
                  {!isEmpty(aggregateData) ? (
                    <>
                      <Row type="flex">
                        <PrimaryTableRowText text={`Orders:‏‏‎ ‎`} />
                        <StandardTableRowText
                          text={
                            aggregateData &&
                            aggregateData.overallData &&
                            aggregateData.overallData.aggEvents &&
                            aggregateData.overallData.aggEvents
                              .transactionsTotalCount
                              ? aggregateData.overallData.aggEvents
                                  .transactionsTotalCount
                              : 0
                          }
                        />
                      </Row>
                      <Row type="flex">
                        <PrimaryTableRowText text={`Rev:‏‏‎ ‎`} />
                        <StandardTableRowText
                          text={formatCurrency(
                            aggregateData &&
                              aggregateData.overallData &&
                              aggregateData.overallData.aggEvents &&
                              aggregateData.overallData.aggEvents
                                .transactionTotalRevenue
                              ? parseFloat(
                                  aggregateData.overallData.aggEvents
                                    .transactionTotalRevenue
                                )
                              : 0,
                            currencyFormat
                          )}
                        />
                      </Row>
                      <Row type="flex">
                        <PrimaryTableRowText text={`ROAS:‏‏‎ ‎`} />
                        <StandardTableRowText
                          text={
                            aggregateData.overallData.aggEvents
                              .transactionTotalRevenue > 0
                              ? budgetSpent && budgetSpent > 0
                                ? (
                                    parseFloat(
                                      aggregateData.overallData.aggEvents
                                        .transactionTotalRevenue / budgetSpent
                                    ) * 100
                                  ).toFixed(2) + "%"
                                : "N/A"
                              : "N/A"
                          }
                        />
                      </Row>
                    </>
                  ) : (
                    <PrimaryTableRowText text={`-`} />
                  )}
                </Col>
              </Row>
            );
          }
          return (
            <Row type="flex" justify="center" align="middle">
              <Col>
                <MiniChart
                  data={
                    aggregateData &&
                    aggregateData.overallData &&
                    aggregateData.overallData.aggEvents &&
                    aggregateData.overallData.aggEvents.transactionsByDate &&
                    Array.isArray(
                      aggregateData.overallData.aggEvents.transactionsByDate
                    ) &&
                    aggregateData.overallData.aggEvents.transactionsByDate
                      .length > 0
                      ? aggregateData.overallData.aggEvents.transactionsByDate
                      : []
                  }
                  yAxisLeft="transactionsTotalCount"
                />
              </Col>
            </Row>
          );
        },
      };
    },
    "Sign Ups": field => {
      return {
        title: <TableColumnTitle text={"Sign Ups"} />,
        dataIndex: null,
        key: `aggregateData.overallData.${field.key}`,
        align: "left",
        width: "8%",
        ellipsis: true,
        footerSums: [
          {
            property: "aggregateData.overallData.signupsOverallData",
            formatter: "",
            title: "Sign Ups",
          },
        ],
        render: (text, { aggregateData, attributionTargets }) => {
          return (
            <Row type="flex" justify="start" align="middle">
              <Col style={{ minWidth: "50px" }}>
                {!isEmpty(aggregateData) ? (
                  <>
                    <Row type="flex">
                      <PrimaryTableRowText text={`Sign Up:‏‏‎ ‎`} />
                      <StandardTableRowText
                        text={`${Number(
                          parseInt(
                            aggregateData.overallData.signupsOverallData ===
                              null
                              ? 0
                              : aggregateData.overallData.signupsOverallData
                          ).toFixed(0)
                        ).toLocaleString()}`}
                      />
                    </Row>
                  </>
                ) : (
                  <PrimaryTableRowText text={`-`} />
                )}
              </Col>
            </Row>
          );
        },
      };
    },
    "Walk ins": field => {
      return {
        title: <TableColumnTitle text={"Walk Ins"} />,
        dataIndex: "aggVWI",
        key: `aggVWI`,
        align: "left",
        width: "8%",
        ellipsis: true,
        footerStyle: "block",
        footerSums: [
          {
            property: "aggregateData.overallData.aggVWI",
            formatter: "",
            title: "Walk Ins",
          },
          {
            property: "aggregateData.overallData.totalObservations",
            formatter: "",
            title: "Observations",
          },
        ],
        render: (text, { aggregateData, attributionTargets }) => {
          return (
            <Row type="flex" justify="start" align="middle">
              <Col style={{ minWidth: "50px" }}>
                {!isEmpty(aggregateData) ? (
                  <>
                    <Row type="flex">
                      <PrimaryTableRowText text={`Walk Ins:‏‏‎ ‎`} />
                      <StandardTableRowText
                        text={` ${Number(
                          parseInt(aggregateData.overallData.aggVWI).toFixed(0)
                        ).toLocaleString()}`}
                      />
                    </Row>
                    <Row type="flex">
                      <PrimaryTableRowText text={`Observations:‏‏‎ ‎`} />
                      <StandardTableRowText
                        text={`${Number(
                          parseInt(
                            aggregateData.overallData.totalObservations === null
                              ? 0
                              : aggregateData.overallData.totalObservations
                          ).toFixed(0)
                        ).toLocaleString()}`}
                      />
                    </Row>
                  </>
                ) : (
                  <PrimaryTableRowText text={`-`} />
                )}
              </Col>
            </Row>
          );
        },
      };
    },
    generic: field => {
      return {
        title: <TableColumnTitle text={field.title} />,
        dataIndex: field.key,
        key: `aggregateData.overallData.${field.dataAccessor}`,
        align: "center",
        width: "8%",

        render: (text, { aggregateData, data }) => {
          return (
            <Row type="flex" justify="center" align="middle">
              <Col style={{ minWidth: "75px" }}>
                {!isEmpty(aggregateData) && field.title !== "Impressions" ? (
                  <PrimaryTableRowText
                    text={`${Number(
                      aggregateData.overallData[field.dataAccessor]
                    ).toLocaleString()}`}
                  />
                ) : (
                  field.title !== "Impressions" && (
                    <PrimaryTableRowText text={`-`} />
                  )
                )}
              </Col>
              {field.title === "Impressions" && (
                <Col>
                  <MiniChart
                    data={
                      aggregateData && aggregateData.byDate
                        ? aggregateData.byDate
                        : []
                    }
                    yAxisLeft={field.title.toLowerCase()}
                  />
                </Col>
              )}
            </Row>
          );
        },
      };
    },
    Attribution: field => {
      return {
        title: <TableColumnTitle text={field.title} />,
        dataIndex: "",
        key: "",
        align: "left",
        width: "8%",
        footerContent: " ",

        render: (
          data,
          {
            attributionTargets,
            transactionTarget,
            transactionsConfig,
            signUpCampaign,
          }
        ) => {
          const transactionIcon = () => {
            if (transactionTarget) {
              if (
                transactionsConfig &&
                transactionsConfig.traffId &&
                transactionsConfig.tracksBasketItems
              ) {
                return (
                  <span
                    title={transactionsConfig && transactionsConfig.appId}
                    role="img"
                    className="anticon"
                    style={{ verticalAlign: "-5px" }}
                  >
                    <ShoppingCartBasketItemLocation
                      style={{
                        width: "1.6rem",
                        height: "auto",
                        opacity: "1.0",
                      }}
                    />
                  </span>
                );
              }
              if (transactionsConfig && transactionsConfig.tracksBasketItems) {
                return (
                  <span
                    title={transactionsConfig && transactionsConfig.appId}
                    role="img"
                    className="anticon"
                    style={{ verticalAlign: "-5px" }}
                  >
                    <ShoppingCartBasketItem
                      style={{
                        width: "1.6rem",
                        height: "auto",
                        opacity: "1.0",
                      }}
                    />
                  </span>
                );
              }

              if (transactionsConfig && transactionsConfig.traffId) {
                return (
                  <span
                    title={transactionsConfig && transactionsConfig.appId}
                    role="img"
                    className="anticon"
                    style={{ verticalAlign: "-5px" }}
                  >
                    <ReactLogo
                      style={{
                        fontSize: "30px",
                        opacity: "1.0",
                      }}
                    />
                  </span>
                );
              } else {
                return (
                  <ShoppingCartOutlined
                    title={transactionsConfig && transactionsConfig.appId}
                    style={{
                      fontSize: "30px",
                      opacity: "1.0",
                    }}
                  />
                );
              }
            } else {
              return (
                <ShoppingCartOutlined
                  style={{
                    fontSize: "30px",
                    opacity: "0.2",
                  }}
                />
              );
            }
          };

          const walkinIcon = () => (
            <WalkinsEnabled
              style={{
                width: "2.2rem",
                height: "auto",
                opacity: attributionTargets.length > 0 ? "1.0" : "0.2",
              }}
            />
          );

          const signUpIcon = () => (
            <SignUpEnabled
              style={{
                width: "1.6rem",
                height: "auto",
                opacity: signUpCampaign ? "1.0" : "0.2",
              }}
            />
          );

          return (
            <Row>
              <Col style={{ minWidth: "100px" }}>
                <Space align="center">
                  {transactionIcon()}
                  {walkinIcon()}
                  {signUpIcon()}
                </Space>
              </Col>
            </Row>
          );
        },
      };
    },
  };

  const fieldsToRender = Object.keys(possibleFields).filter(
    field => selectedTableColumns.indexOf(field) >= 0
  );

  const columns = fieldsToRender.map(key => {
    const field = possibleFields[key];
    return getColumn[field.title]
      ? getColumn[field.title](field)
      : getColumn.generic(field);
  });

  columns.push({
    title: (
      <TableColumnsDropDown
        onTableColChange={onTableColChange}
        selectedTableColumns={selectedTableColumns}
        possibleFields={possibleFields}
      />
    ),
    dataIndex: "more",
    key: "more",
    width: "4%",
    // Todo: Icon for "more" here rotated 90 degrees
    render: text => null,
  });

  const dataWithCampaignByDate =
    data &&
    data.map(campaignOrder => {
      let newCampaignOrder = campaignOrderPreCalculate(campaignOrder);
      return {
        ...newCampaignOrder,
        data: aggregateDisplayReports(
          newCampaignOrder.campaigns.map(campaign => campaign.data)
        ),
      };
    });

  const rowSelection = {
    onChange: onRowChange,
    selectedRowKeys,
    columnWidth: "5%",
  };

  return (
    <div style={{ backgroundColor: "#fff", border: "1px solid #e8e8e8" }}>
      <PaginationTable
        summary={dataWithCampaignByDate}
        rowSelection={rowSelection}
        onRow={data => ({
          onClick: event => {
            const { id } = data;

            if (event.target.classList.contains("ant-table-selection-column")) {
              // If the clicked row is already selected, deselect it; otherwise, select it
              if (selectedRowKeys.includes(data.id)) {
                onRowChange(
                  selectedRowKeys.filter(key => key !== data.id),
                  selectedRows.filter(row => row.id !== data.id)
                );
              } else {
                onRowChange(
                  [...selectedRowKeys, data.id],
                  [...selectedRows, data]
                );
              }
            } else {
              // perform your redirect logic here
              history.push(`/campaigns/details/${id}`);
            }
          },
        })}
        data={dataWithCampaignByDate}
        paginationSize={pageSize}
        showSizeChanger
        size={"default"}
        columns={columns}
        scroll={{ x: 2000 }}
        total={total}
        current={current}
        pageSize={pageSize}
        bordered={false}
        currencyFormat={currencyFormat}
        onChange={(paginationObject, filtersObject, sorterObject, extra) => {
          if (!isColumnChange) {
            const nextPage = location.pathname.split("/");
            nextPage[3] = paginationObject.current;
            const searchParams = new URLSearchParams(window.location.search);

            if (searchParams.get("sort")) searchParams.delete("sort");
            if (searchParams.get("pageSize")) searchParams.delete("pageSize");

            if (!isEmpty(paginationObject) && paginationObject.pageSize > 10) {
              searchParams.append("pageSize", paginationObject.pageSize);
            }

            if (!isEmpty(sorterObject.column)) {
              searchParams.append(
                "sort",
                `${sorterObject.field}_${
                  isNil(sorterObject.order) || sorterObject.order === "ascend"
                    ? "ASC"
                    : "DESC"
                }`
              );
            }

            history.push({
              pathname: nextPage.join("/"),
              search: `?${searchParams.toString()}`,
            });
          }
        }}
        showAllPaginationOption={false}
      />
    </div>
  );
};

export default withRouter(CampaignsTable);
