import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Col,
  Row,
  Modal,
  Select,
  message,
  InputNumber,
} from "antd";
import Button from "../../../../shared/globalStyling/Button";
import {
  defaultRadius,
  stateList,
} from "../../../../../core/utils/constants/constants";
import { metersToMiles, kmToMiles } from "../../../../../core/utils/campaigns";
import { getFormattedAddress } from "../../../../shared/maps/utils/mapUtils";
import { OrganizationConfig } from "../../configs/organizationConfig";
import { MapConfig } from "../../../../shared/maps/configs/mapConfigs";

export default function TargetingLocationForm({
  selectedLocation,
  isFormOpen,
  closeForm,
  submitForm,
  google,
  targetingLocations,
}) {
  const { initialLocation } = OrganizationConfig;
  const { countryType } = MapConfig;

  const [form] = Form.useForm();
  const [location, setLocation] = useState(initialLocation);
  const [country, setCountry] = useState("USA");
  const [street, setStreet] = useState();
  const [circles, setCircles] = useState([]);
  const [formTitle, setFormTitle] = useState("Create Targeting Location");
  const [isOutsideUSAndCanada, setIsOutsideUSAndCanada] = useState(false);
  const [radiusUnit, setRadiusUnit] = useState("miles");

  useEffect(() => {
    searchInputPlacesInit();
  });

  useEffect(() => {
    if (selectedLocation) {
      setStreet(selectedLocation.street || "");

      if (
        selectedLocation.geoframe &&
        selectedLocation.geoframe.circles &&
        selectedLocation.geoframe.circles[0] &&
        selectedLocation.geoframe.circles[0].coordinate
      ) {
        const coordinate = selectedLocation.geoframe.circles[0].coordinate;
        const location = new google.maps.LatLng(coordinate.lat, coordinate.lng);
        updateCirclesOverlay({
          location,
        });
      }

      setIsOutsideUSAndCanada(
        selectedLocation.country !== "USA" &&
          selectedLocation.country !== "CANADA"
      );

      setFormTitle("Edit Targeting Location");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]);

  const onCloseForm = () => {
    form.resetFields();
    setTimeout(() => {
      closeForm();
    }, 500);
  };

  const searchInputPlacesInit = () => {
    const input = document.getElementById("search-input-tl");

    input.addEventListener("keydown", e => {
      if (e.key === "Enter") {
        e.preventDefault();
      }
    });

    var options = {
      componentRestrictions: MapConfig.componentRestrictions, // This includes the country restriction
    };

    const searchBox = new google.maps.places.Autocomplete(input, options);

    searchBox.addListener("place_changed", () => {
      const place = searchBox.getPlace();

      if (!place) {
        message.error("Error: No places found!");
        return;
      }

      const location = place.geometry.location;

      updateFormField(place);
      updateCirclesOverlay({ location });

      setLocation({
        lat: location.lat(),
        lng: location.lng(),
      });
    });
  };

  const updateFormField = place => {
    const { state, city, zip, country, address } = getFormattedAddress({
      result: place,
    });

    const notInUSAorCanada = country !== "US" && country !== "CA";

    setIsOutsideUSAndCanada(notInUSAorCanada);

    form.setFieldsValue({
      state: notInUSAorCanada ? "" : state.replace(/_/g, " ").toUpperCase(),
      city,
      zip,
      formattedAddress: place.formatted_address,
      province: notInUSAorCanada ? state : "",
    });

    setCountry(countryType[country]);
    setStreet(address);
  };

  const updateCirclesOverlay = ({ location }) => {
    const newCircle = {
      overlay: new google.maps.Circle({
        center: { lat: location.lat(), lng: location.lng() },
        radius: defaultRadius,
      }),
      type: "circle",
    };
    setCircles([newCircle]);
  };

  const onSubmit = () => {
    form.validateFields();

    if (
      !selectedLocation &&
      targetingLocations &&
      targetingLocations.find(
        location =>
          location.name.toLowerCase() ===
          form.getFieldsValue().name.toLowerCase()
      )
    ) {
      message.error("Target location name already exists!");
      return;
    }

    let radius = form.getFieldValue("radius");
    radius = convertRadius(radius, radiusUnit);

    function convertRadius(radius, radiusUnit) {
      switch (radiusUnit) {
        case "kilometers":
          return kmToMiles(radius);
        case "meters":
          return metersToMiles(radius);
        default:
          return radius; // miles
      }
    }

    const data = {
      ...form.getFieldsValue(),
      ...location,
      street,
      country,
      circles: [
        {
          ...circles[0],
          overlay: {
            ...circles[0].overlay,
            radius,
          },
        },
      ],
      radiusUnit,
      audienceType: "TARGETING",
    };

    // Replace spaces with underscores and convert to uppercase for the 'state' property
    if (!data.state) {
      return message.error("Invalid State or State is not supported yet.");
    }

    data.state = data.state.replace(/ /g, "_").toUpperCase();

    if (isOutsideUSAndCanada) {
      delete data.state;
    } else {
      delete data.province;
    }

    submitForm(data);
  };

  const getInitialRadius = () => {
    if (
      selectedLocation &&
      selectedLocation.geoframe &&
      selectedLocation.geoframe.circles &&
      selectedLocation.geoframe.circles[0] &&
      selectedLocation.geoframe.circles[0].radius
    ) {
      return selectedLocation.geoframe.circles[0].radius * 1000;
    }
    return defaultRadius;
  };

  return (
    <>
      <Modal
        visible={isFormOpen}
        title={formTitle}
        onOk={() => onSubmit()}
        onCancel={onCloseForm}
        footer={null}
      >
        <Row gutter={18} justify="center">
          <Form
            name="form-name"
            form={form}
            layout="vertical"
            onFinish={() => onSubmit()}
            autoComplete="off"
            validateTrigger="onBlur"
            initialValues={{
              name: selectedLocation ? selectedLocation.name : "",
              formattedAddress: selectedLocation
                ? selectedLocation.formattedAddress
                : "",
              city: selectedLocation ? selectedLocation.city : "",
              state: selectedLocation ? selectedLocation.state : "",
              province:
                selectedLocation && selectedLocation.province
                  ? selectedLocation.province
                  : "",
              zip: selectedLocation ? selectedLocation.zip : "",
              radius: getInitialRadius(),
            }}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Target name is required!",
                },
              ]}
            >
              <Input name="name" autoComplete="no" />
            </Form.Item>
            <Form.Item
              label="Address (Type and select the address) "
              name="formattedAddress"
              rules={[
                {
                  required: true,
                  message: "Address is required!",
                },
              ]}
            >
              <input
                type="input"
                placeholder="Address"
                name="formattedAddress"
                id="search-input-tl"
                className="ant-input"
                onFocus={e => {
                  e.target.setAttribute("autocomplete", "no");
                }}
              />
            </Form.Item>
            <Form.Item
              label="Radius"
              name="radius"
              tooltip="The radius will be automatically converted to the dashboard's default unit, miles."
              rules={[
                {
                  required: true,
                  message: "Invalid radius value!",
                },
              ]}
            >
              <InputNumber
                min={1}
                name="radius"
                style={{ width: "100%" }}
                max={999999999999999} // max number to prevent overflow
                addonAfter={
                  <Select
                    value={radiusUnit}
                    onChange={value => setRadiusUnit(value)}
                    style={{ width: 120 }}
                  >
                    <Select.Option value="miles">Miles</Select.Option>
                    <Select.Option value="meters">Meters</Select.Option>
                    <Select.Option value="kilometers">Kilometers</Select.Option>
                  </Select>
                }
              />
            </Form.Item>
            <Row>
              <Col xs={24} xl={9} style={{ paddingRight: 5 }}>
                <Form.Item
                  label="City"
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: "City is required!",
                    },
                  ]}
                  wrapperCol={{ span: 24 }}
                >
                  <Input placeholder="City" name="city" autoComplete="no" />
                </Form.Item>
              </Col>
              <Col xs={24} xl={9}>
                {isOutsideUSAndCanada ? (
                  <Form.Item
                    label="State"
                    name="province"
                    rules={[
                      {
                        required: true,
                        message: "State is required!",
                      },
                    ]}
                    wrapperCol={{ span: 24 }}
                  >
                    {/* This is the state for outside US and Canada */}
                    <Input placeholder="State" name="province" />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label="State"
                    name="state"
                    rules={[
                      {
                        required: true,
                        message: "State is required!",
                      },
                    ]}
                    wrapperCol={{ span: 24 }}
                  >
                    <Select
                      showSearch
                      placeholder="Select State"
                      name="state"
                      style={{ width: "100%", borderRadius: "0px" }}
                    >
                      {stateList.map(item => (
                        <Select.Option key={item} value={item}>
                          {item.replace("_", " ")}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Col>
              <Col xs={24} xl={6} style={{ paddingLeft: 5 }}>
                <Form.Item
                  label="Zip"
                  name="zip"
                  rules={[{ required: true, message: "Zip is required!" }]}
                  wrapperCol={{ span: 24 }}
                >
                  <Input placeholder="Zip" name="zip" autoComplete="no" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Row justify="end">
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
                <Button
                  type="primary"
                  ghost="true"
                  onClick={() => onCloseForm()}
                >
                  Cancel
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Row>
      </Modal>
    </>
  );
}
