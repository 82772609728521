import React, { createContext, useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "react-apollo";

// MUTATIONS
import CREATE_CAMPAIGN_ORDER_REQUESTOR from "../../GraphQl/Mutations/CREATE_CAMPAIGN_ORDER_REQUESTOR";
import UPDATE_CAMPAIGN_ORDER_REQUESTOR from "../../GraphQl/Mutations/UPDATE_CAMPAIGN_ORDER_REQUESTOR";

// QUERIES
import CURRENT_USER from "../../GraphQl/Queries/CURRENT_USER";
import GET_CAMPAIGN_ORDER_REQUESTOR_ADVERTISERS from "../../GraphQl/Queries/GET_CAMPAIGN_ORDER_REQUESTOR_ADVERTISERS";
import GET_CAMPAIGN_ORDER_REQUESTOR from "../../GraphQl/Queries/GET_CAMPAIGN_ORDER_REQUESTOR";

const CampaignRequestorContext = createContext({});

export const CampaignRequestorProvider = props => {
  const { children } = props;
  const [selectedAdvertiser, setSelectedAdvertiser] = useState(null);
  const [currentUserOrg, setCurrentUserOrg] = useState(null);

  // QUERIES
  const [
    fetchChildrenOrgs,
    { data: dataChildrenOrgs, loading: loadingChildrenOrgs },
  ] = useLazyQuery(GET_CAMPAIGN_ORDER_REQUESTOR_ADVERTISERS);

  const [
    fetchCampaignOrder,
    { data: dataCampaignOrder, loading: loadingCampaignOrder },
  ] = useLazyQuery(GET_CAMPAIGN_ORDER_REQUESTOR, {
    fetchPolicy: "cache-and-network",
  });

  const {
    data: { currentUser },
  } = useQuery(CURRENT_USER, {
    onCompleted: ({ currentUser }) => {
      if (currentUser) {
        fetchChildrenOrgs({
          variables: {
            where: {
              OR: [
                {
                  parentOrg_some: { id: currentUser.defaultRole.org.id },
                },
                {
                  id: currentUser.defaultRole.org.id,
                },
              ],
            },
          },
        });

        setSelectedAdvertiser(currentUser.defaultRole.org);
      }
    },
  });

  // MUTATIONS
  const [
    createCampaignOrder,
    { loading: loadingCreateCampaignOrder },
  ] = useMutation(CREATE_CAMPAIGN_ORDER_REQUESTOR, {
    onCompleted: ({ createCampaignOrderRequestor }) => {
      fetchCampaignOrder({
        variables: {
          where: {
            id: createCampaignOrderRequestor.id,
          },
        },
      });
    },
  });

  const [
    updateCampaignOrder,
    { loading: loadingUpdateCampaignOrder },
  ] = useMutation(UPDATE_CAMPAIGN_ORDER_REQUESTOR, {
    update: (cache, { data: { updateCampaignOrderRequestor } }) => {
      cache.writeQuery({
        query: GET_CAMPAIGN_ORDER_REQUESTOR,
        variables: {
          where: {
            id: updateCampaignOrderRequestor.id,
          },
        },
        data: {
          campaignOrder: updateCampaignOrderRequestor,
        },
      });
    },
  });

  // TODO: This should be movd to user context
  useEffect(() => {
    if (currentUser) {
      setCurrentUserOrg(currentUser.defaultRole.org);
    }
  }, [currentUser]);

  return (
    <CampaignRequestorContext.Provider
      value={{
        currentUser,
        childrenOrg: dataChildrenOrgs ? dataChildrenOrgs.orgs : [],
        loadingChildrenOrgs,
        selectedAdvertiser,
        currentUserOrg,
        currentCampaignOrder: dataCampaignOrder
          ? dataCampaignOrder.campaignOrder
          : null,
        loadingCreateCampaignOrder,
        loadingCampaignOrder,
        loadingUpdateCampaignOrder,
        createCampaignOrder,
        updateCampaignOrder,
        setSelectedAdvertiser,
        fetchCampaignOrder,
      }}
      {...props}
    >
      {children}
    </CampaignRequestorContext.Provider>
  );
};

export default CampaignRequestorContext;
